import Image from "next/image";
import Link from "next/link";

import bgImage from "../../public/images/bg/bg-image-10.jpg";

const author = {
  _id: "63bb1e8cd1e577a44f9bd5cd",
  name: "Emin Başbayan",
  username: "eminbasbayan",
  email: "emin.basbayan@icloud.com",
  avatar: "https://avatars.githubusercontent.com/u/48470345?v=4",
  bio: "Full Stack Dev.",
  roles: ["user"],
  mainRole: "admin",
  provider: "github",
  following: [],
  createdAt: {
    $date: "2023-01-08T19:50:36.857Z",
  },
  updatedAt: {
    $date: "2024-06-01T19:26:49.691Z",
  },
  __v: 202,
  views: 79,
  github_url: "https://github.com/eminbasbayan",
};

const BlogBreadCrumb = ({ matchedBlog }) => {
  return (
    <>
      <div className="breadcrumb-image-container breadcrumb-style-max-width">
        <div className="breadcrumb-image-wrapper">
          <div className="breadcrumb-dark">
            <Image src={bgImage} alt="Education Images" />
          </div>
        </div>
        <div className="breadcrumb-content-top text-center">
          <ul className="meta-list justify-content-center mb--10">
            <li className="list-item">
              <div className="author-thumbnail">
                {matchedBlog && (
                  <Image
                    src={author.avatar}
                    width={494}
                    height={494}
                    alt="blog-image"
                  />
                )}
              </div>
              {matchedBlog && (
                <div className="author-info">
                  <Link href="#">
                    <strong>{author.name}</strong>
                  </Link>{" "}
                  -{" "}
                  <Link href="#">
                    <strong>Senior Full Stack Developer</strong>
                  </Link>
                </div>
              )}
            </li>
            {/*  {matchedBlog && (
              <li className="list-item">
                <i className="feather-clock"></i>
                <span>{matchedBlog.date}</span>
              </li>
            )} */}
          </ul>
          {matchedBlog && <h1 className="title">{matchedBlog.title}</h1>}
          {matchedBlog && <p>{matchedBlog.desc}</p>}
        </div>
      </div>
    </>
  );
};

export default BlogBreadCrumb;
