import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import CommentForm from "./Blog-Sections/CommentForm";
import Comment from "./Blog-Sections/Comment";
import SyntaxHighlighter from "react-syntax-highlighter";
import { useRouter } from "next/router";
import HTMLReactParser from "html-react-parser";
import { monokaiSublime } from "react-syntax-highlighter/dist/cjs/styles/hljs";

const BlogDetails = ({ matchedBlog }) => {
  const [comments, setComments] = useState([]);
  const router = useRouter();

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const res = await fetch(`/api/comments?blogId=${matchedBlog?._id}`);
        const data = await res.json();
        if (data.success) {
          setComments(data.data);
        } else {
          console.error("Yorumlar getirilemedi:", data.error);
        }
      } catch (error) {
        console.error("Yorumlar getirilemedi:", error);
      }
    };

    fetchComments();
  }, [matchedBlog]);

  function handleSubmit(newComment) {
    setComments([...comments, newComment]);
  }

  useEffect(() => {
    const preElements = document.querySelectorAll("pre");

    preElements.forEach((preElement) => {
      const codeElement = preElement.querySelector("code");
      if (!codeElement) return;

      const copyButton = document.createElement("button");
      copyButton.textContent = "Kopyala";
      copyButton.className = "copy-button";
      copyButton.style.position = "absolute";
      copyButton.style.right = "0";
      copyButton.style.top = "0";
      copyButton.addEventListener("click", () => {
        navigator.clipboard.writeText(codeElement.textContent).then(
          () => {
            copyButton.textContent = "Kopyalandı";
            setTimeout(() => {
              copyButton.textContent = "Kopyala";
            }, 2000);
          },
          (err) => {
            console.error("Kod kopyalanamadı:", err);
          }
        );
      });

      preElement.style.position = "relative";
      preElement.appendChild(copyButton);
    });
  }, [router?.query?.slug]);

  const options = {
    replace: (domNode) => {
      if (domNode.name === "pre" && domNode.children[0]?.name === "code") {
        const language = domNode.children[0]?.attribs?.class || ""; // Sınıf adını kullanarak dil belirlemeye çalışın
        const code = domNode.children[0]?.children[0]?.data;

        return (
          <SyntaxHighlighter
            language={language.replace("language-", "")}
            style={monokaiSublime}
          >
            {code}
          </SyntaxHighlighter>
        );
      }
    },
  };

  return (
    <>
      <div className="content">
        <div className="post-thumbnail mb--30 position-relative wp-block-image alignwide">
          <figure>
            {matchedBlog.thumbnail && (
              <Image
                src={matchedBlog.thumbnail.url}
                width={1085}
                height={645}
                priority
                alt="Blog Images"
              />
            )}
            {matchedBlog.caption && (
              <figcaption>{matchedBlog.caption}</figcaption>
            )}
          </figure>
        </div>
        {HTMLReactParser(matchedBlog.content, options)}

        {/* <div className="social-share-block mt--30">
          <div className="post-like">
            <Link href="#">
              <i className="feather feather-thumbs-up"></i>
              <span>2.2k Like</span>
            </Link>
          </div>
          <ul className="social-icon social-default transparent-with-border">
            <li>
              <Link href="#">
                <i className="feather-facebook"></i>
              </Link>
            </li>
            <li>
              <Link href="#">
                <i className="feather-twitter"></i>
              </Link>
            </li>
            <li>
              <Link href="#">
                <i className="feather-instagram"></i>
              </Link>
            </li>
            <li>
              <Link href="#">
                <i className="feather-linkedin"></i>
              </Link>
            </li>
          </ul>
        </div> */}

        {/* <div className="rbt-comment-area">
          <CommentForm
            blogId={matchedBlog._id}
            onSubmitSuccess={handleSubmit}
          />
        </div> */}

        {/* blog comments */}
        {/*   <div className="rbt-comment-area">
          {comments?.length > 0 && <h4 className="title">Yorumlar</h4>}
          <ul className="comment-list">
            {comments.map((comnt, index) => (
              <Comment comnt={comnt} key={index} />
            ))}
          </ul>
        </div> */}
      </div>
    </>
  );
};

export default BlogDetails;
