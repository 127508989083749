import TextArea from "@/components/Common/Form/TextArea";
import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useSession } from "next-auth/react";
import toast from "react-hot-toast";

const CommentForm = ({ blogId, parentId, onSubmitSuccess }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const { data: session } = useSession();

  const onSubmit = async (data) => {
    const commentData = {
      belongsTo: blogId,
      owner: session?.user.id,
      content: data.message,
      chiefComment: !parentId,
    };

    if (parentId) {
      commentData.repliedTo = parentId;
    }

    try {
      const res = await axios.post("/api/comments", commentData);
      if (res.data.success) {
        setValue("message", "");
        if (onSubmitSuccess) {
          toast.success("Yorum başarıyla eklendi");
          onSubmitSuccess(res.data.data);
        }
      } else {
        alert("Yorum gönderilemedi.");
      }
    } catch (error) {
      alert("Yorum gönderilemedi.");
    }
  };

  return (
    <div className={`comment-respond ${parentId && "mt-0"}`}>
      {!parentId && <h4 className="title">{"Yorum Yap"}</h4>}

      <form onSubmit={handleSubmit(onSubmit)}>
        {!parentId && (
          <p className="comment-notes">Düşüncelerinizi bizimle paylaşın.</p>
        )}

        <div className="row row--10">
          <div className="col-12 mb--30">
            <TextArea
              name="message"
              label={parentId ? "" : "Bir yorum yazın"}
              register={register}
              errors={errors}
              onChange={(e) => setValue("message", e.target.value)}
            />
          </div>
          <div className="col-lg-12">
            <button
              type="submit"
              className="rbt-btn btn-gradient icon-hover radius-round btn-md"
            >
              <span className="btn-text">Yorumu Gönder</span>
              <span className="btn-icon">
                <i className="feather-arrow-right"></i>
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CommentForm;
