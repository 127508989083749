import Image from "next/image";
import Link from "next/link";
import React, { useState } from "react";
import CommentForm from "./CommentForm";
import { useSession } from "next-auth/react";

const Comment = ({ comnt }) => {
  const [replying, setReplying] = useState(false);
  const [replies, setReplies] = useState(comnt.replies || []);
  const { data: session } = useSession();

  const handleReplyClick = (e) => {
    e.preventDefault();
    setReplying(!replying);
  };

  const handleNewReply = (newReply) => {
    setReplies([...replies, newReply]);
    setReplying(false);
  };

  return (
    <>
      <li className="comment">
        <div className="comment-body">
          <div className="single-comment">
            <div className="comment-img">
              <Image
                src={comnt?.owner?.avatar?.url || session?.user?.avatar?.url}
                width={70}
                height={70}
                priority
                alt="Author Images"
              />
            </div>
            <div className="comment-inner">
              <h6 className="commenter">
                <Link href="#">
                  {comnt?.owner?.firstName || session?.user?.firstName}{" "}
                  {comnt?.owner?.lastName || session?.user?.lastName}
                </Link>
              </h6>
              <div className="comment-meta">
                <div className="time-spent">
                  {new Date(comnt.createdAt).toLocaleString()}
                </div>
                <div className="reply-edit">
                  <div className="reply">
                    <Link
                      className="comment-reply-link"
                      href="#"
                      onClick={handleReplyClick}
                    >
                      Cevapla
                    </Link>
                  </div>
                </div>
              </div>
              <div className="comment-text">
                <p className="b2">{comnt.content}</p>
              </div>
            </div>
          </div>
        </div>
        {replying && (
          <div className="reply-form">
            <CommentForm
              blogId={comnt.belongsTo}
              parentId={comnt._id}
              onSubmitSuccess={handleNewReply}
            />
          </div>
        )}
        {replies.length > 0 && (
          <ul className="children">
            {replies.map((child, childIndex) => (
              <li className="comment" key={childIndex}>
                <div className="comment-body">
                  <div className="single-comment">
                    <div className="comment-img">
                      <Image
                        src={
                          child?.owner?.avatar?.url || session?.user?.avatar?.url
                        }
                        width={70}
                        height={70}
                        priority
                        alt="Author Images"
                      />
                    </div>
                    <div className="comment-inner">
                      <h6 className="commenter">
                        <Link href="#">
                        {comnt?.owner?.firstName || session?.user?.firstName}{" "}
                        {comnt?.owner?.lastName || session?.user?.lastName}
                        </Link>
                      </h6>
                      <div className="comment-meta">
                        <div className="time-spent">
                          {new Date(child.createdAt).toLocaleString()}
                        </div>
                      </div>
                      <div className="comment-text">
                        <p className="b2">{child.content}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </li>
    </>
  );
};

export default Comment;
