"use client";

import React from "react";

const TextArea = ({ name, label, register, errors, value, onChange }) => (
  <div className="rbt-form-group">
    <label htmlFor={name}>{label}</label>
    <textarea
      id={name}
      name={name}
      cols="20"
      rows="5"
      {...register(name)}
      value={value}
      onChange={onChange}
    ></textarea>
    {errors[name] && <div className="text-danger">{errors[name].message}</div>}
  </div>
);

export default TextArea;
