import { useEffect, useState } from "react";
import Image from "next/image";
import axios from "axios";

const Instagram = () => {
  const [instagramData, setInstagramData] = useState([]);

  useEffect(() => {
    // API'den veri çekme
    const fetchInstagramData = async () => {
      try {
        const response = await axios.get("/api/instagram");
        const data = response.data;
   
        if (data) {
          setInstagramData(data);
        }
      } catch (error) {
        console.error("Error fetching Instagram data", error);
      }
    };

    fetchInstagramData();
  }, []);

  return (
    <div className="container-fluid mb--5">
      <div className="row g-3">
        {instagramData && instagramData.length > 0 ? (
          instagramData.map((data, index) => (
            <div className="col-lg-2 col-md-4 col-sm-6 col-6" key={index}>
              <div className="instagram-grid">
                <a
                  href={data.permalink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={data.media_url}
                    width={384}
                    height={431}
                    unoptimized 
                    alt="instagram"
                  />
                  <span className="user-info">
                    <span className="icon">
                      <i className="icon-instagram"></i>
                    </span>
                    <span className="user-name">@bilgenyazilimakademisi</span>
                  </span>
                </a>
              </div>
            </div>
          ))
        ) : (
          <p>Loading...</p> // Veriler yüklenirken kullanıcıya bir mesaj gösterebilirsiniz
        )}
      </div>
    </div>
  );
};

export default Instagram;
