import React from "react";
import { useRouter } from "next/router";
import PageHead from "@/pages/Head";
import { Provider } from "react-redux";
import Store from "@/redux/store";
import Context from "@/context/Context";
import MobileMenu from "@/components/Header/MobileMenu";
import HeaderStyleTen from "@/components/Header/HeaderStyle-Ten";
import Cart from "@/components/Header/Offcanvas/Cart";
import BackToTop from "@/pages/backToTop";
import Separator from "@/components/Common/Separator";
import BlogDetails from "@/components/Blogs/BlogDetails";
import BlogListItems from "@/components/Blogs/Blog-Sections/BlogList-Items";
import Instagram from "@/components/Instagram/Instagram";
import BlogBreadCrumb from "@/components/Common/Blog-BreadCrumb";
import FooterThree from "@/components/Footer/Footer-Three";
import dbConnect from "@/lib/dbConnect";
import Blog from "@/models/Blog";

const SingleBlog = ({ blogPost, relatedPosts }) => {
  const router = useRouter();

  if (router.isFallback) {
    return <div>Loading...</div>;
  }

  const pageTitle = blogPost.title;
  const pageDescription =
    blogPost.meta || "Blog post about online courses and education";
  const pageImage = blogPost.thumbnail.url || "";
  const pageUrl = `${process.env.NEXTPUBLIC_URL}/${blogPost.slug}`;

  return (
    <>
      <PageHead
        title={pageTitle}
        description={pageDescription}
        image={pageImage}
        keywords="online courses, education, blog"
        url={pageUrl}
      />
      <Provider store={Store}>
        <Context>
          <MobileMenu />
          <HeaderStyleTen headerSticky="rbt-sticky" headerType="" />
          <Cart />

          <div className="rbt-overlay-page-wrapper">
            <BlogBreadCrumb matchedBlog={blogPost} />

            <div className="rbt-blog-details-area rbt-section-gapBottom breadcrumb-style-max-width">
              <div className="blog-content-wrapper rbt-article-content-wrapper">
                <BlogDetails matchedBlog={blogPost} />
         {/*        <div className="related-post pt--60">
                  <div className="section-title text-start mb--40">
                  <span className="subtitle bg-primary-opacity">İlgİlİ Konular</span>
                  <h4 className="title">Sonraki Yazılar</h4>
                  </div>
                  <BlogListItems
                    selectedBlogs={relatedPosts}
                    start={0}
                    end={3}
                    blogDetails
                  />
                </div> */}
              </div>
            </div>

          {/*   <Instagram /> */}
          </div>

          <BackToTop />
          <Separator />
          <FooterThree />
        </Context>
      </Provider>
    </>
  );
};

export async function getStaticPaths() {
  try {
    await dbConnect();

    const blogs = await Blog.find({}, { slug: 1 }).limit(1000).lean();

    const paths = blogs.map((blog) => ({
      params: { slug: blog.slug },
    }));

    return { paths, fallback: true };
  } catch (error) {
    console.error("Error in getStaticPaths:", error);
    return { paths: [], fallback: true };
  }
}

export async function getStaticProps({ params }) {
  try {
    await dbConnect();

    const blogPost = await Blog.findOne({ slug: params.slug }).lean();

    if (!blogPost) {
      return { notFound: true };
    }

    // Blogları sıralama ve ilgili blogdan sonraki 3 blogu alma
    const allBlogs = await Blog.find().sort({ _id: 1 }).lean();
    const currentIndex = allBlogs.findIndex(blog => blog.slug === params.slug);
    const relatedPosts = allBlogs.slice(currentIndex + 1, currentIndex + 4);

    return {
      props: {
        blogPost: JSON.parse(JSON.stringify(blogPost)),
        relatedPosts: JSON.parse(JSON.stringify(relatedPosts)),
      },
    };
  } catch (error) {
    console.error("Error in getStaticProps:", error);
    return { notFound: true };
  }
}

export default SingleBlog;
